body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(235, 237, 244) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.shadow-sm {
  background-color: white !important;
  border-radius: 2%;
}
